import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { z } from "zod";

export const requestSchema = z.object({
  dateFilter: z.object({
    start: z.string(),
    end: z.string(),
  }),
  qualification: z.string(),
  coordinates: z.array(z.number()),
  specialities: z.object({
    hasSedationExperience: z.boolean(),
    hasTrayAssemblyExperience: z.boolean(),
  }),
  distance: z.number(),
  tmz: z.string(),
  isAgent: z.boolean(),
});

const openShiftDaySchema = z.object({
  total: z.number(),
  slots: z
    .object({
      start: z.string(),
      end: z.string(),
      facilityId: z.string(),
    })
    .array(),
  priorityAccess: z.array(z.nativeEnum(ShiftPeriod)),
  am: z.number().optional(),
  pm: z.number().optional(),
  noc: z.number().optional(),
});
export type OpenShiftDay = z.infer<typeof openShiftDaySchema>;

const responseSchema = z.record(z.string(), openShiftDaySchema);

export type OpenShiftRequest = z.infer<typeof requestSchema>;
export type OpenShiftCountResponse = z.infer<typeof responseSchema>;

export interface OpenShiftCountParams {
  startDate: Date;
  endDate: Date;
  qualification?: string;
  coordinates?: [number, number];
  specialities: {
    hasSedationExperience: boolean;
    hasTrayAssemblyExperience: boolean;
  };
  distance: number;
  tmz: string;
  isAgent: boolean;
}

export const GET_OPEN_SHIFT_COUNT_PATH = "/calendar/agentOpenShiftCount";
export const GET_OPEN_SHIFT_COUNT_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_OPEN_SHIFT_COUNT_PATH}`;

export function useOpenShiftCount(
  params: OpenShiftCountParams,
  options: UseGetQueryOptions<OpenShiftCountResponse> = {}
): UseQueryResult<OpenShiftCountResponse> {
  const { startDate, endDate, qualification, coordinates, specialities, distance, tmz, isAgent } =
    params;

  const queryParams: OpenShiftRequest = {
    dateFilter: {
      start: format(startDate, "yyyy-MM-dd"),
      end: format(endDate, "yyyy-MM-dd"),
    },
    qualification: qualification ?? "",
    coordinates: coordinates ?? [],
    specialities,
    distance,
    tmz,
    isAgent,
  };

  return useGetQuery({
    url: GET_OPEN_SHIFT_COUNT_URL,
    queryParams,
    requestSchema,
    responseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading open shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_COUNT_FAILURE,
    },
    ...options,
  });
}
